import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Stores, ColumnList } from '../component'
import Images from '../helpers/Images'
import './Footer.css'

const Footer = () => {


  const navigate = useNavigate();


  const links = [
    {
      name: "Blog",
      onClick: null
    },
    {
      name: "Servicios",
      onClick: null
    },
    {
      name: "Preguntas Frecuentes",
      onClick: null
    },
    {
      name: "Opinión de clientes",
      onClick: null
    },
    {
      name: "Descargar la app",
      onClick: null
    }
  ];

  const legal = [
    {
      name: "Aviso de Privacidad",
      onClick: () => navigate('legal')
    },
    {
      name: "Términos y Condiciones",
      onClick: () => navigate('legal')
    },
    {
      name: "Condiciones Alertas",
      onClick: () => navigate('alerts')
    },
    {
      name: "Condiciones de Gestoría",
      onClick: () => navigate('managment')
    },
    {
      name: "Condiciones de Emergencia Vial",
      onClick: () => navigate('emergency')
    },
  ]
  const contact = [
    {
      name: "hola@outo.mx",
      onClick: () => window.location.href = "mailto:hola@outo.mx"
    }
  ]
  return (
    <div className="footer ">
      <div className="container_text desktop">
        <div className="download">
          <h1 className="section_subtitle">Descarga gratis la app</h1>
          <div>
            <p className="section_description">
              Registra tu auto en menos de 3 minutos y olvídate de las multas y recargos.
            </p>
          </div>
          <div className='stores'>
            <Stores />
          </div>
        </div>
      </div>
      <div className="footer_list">
        <div className='info_outo'>
          <img
            alt=''
            className='logo_white'
            src={Images.outo.logo_white}
          />
          <div className='desktop'>
            <p>
              Recibe Alertas (notificaciones) cuando te pongan una multa, tengas que hacer la verificación, pagar Tenencia o no circules.
            </p>
            <p>
              Además, puedes contratar servicios de Grúa, Ambulancia, Gestoría, Auxilio Vial y muchos más a nivel nacional
            </p>
          </div>
        </div>
        <div className='desktop'>
          <ColumnList
            title="Links"
            list={links}
          />
        </div>
        <div className='desktop'>
          <ColumnList
            title="Legales"
            list={legal}
          />
        </div>
        <div className='contact' >
          <ColumnList
            title="Contacto"
            list={contact}
          />
          <div className="rrss">
            <img
              alt="facebook"
              src={Images.outo.facebook}
              onClick={() => window.open("https://www.facebook.com/Outoapp/")}
            />
            <img
              alt="twitter"
              src={Images.outo.twitter}
              onClick={() => window.open("https://twitter.com/Outomx")}
            />
            <img
              alt="instagram"
              src={Images.outo.instagram}
              onClick={() => window.open("https://www.instagram.com/outo.mx/")}
            />
            <img
              alt="linkedin"
              src={Images.outo.linkedin}
              onClick={() => window.open("https://www.linkedin.com/company/outoapp/mycompany/?viewAsMember=true")}
            />
          </div>
        </div>
      </div>
      <div className="copyright">
        <p> © Alerta Digital SAPI de CV.   Todos los derechos reservados. <span className='desktop'>  2021</span></p>
      </div>
    </div>
  )
}

export default Footer