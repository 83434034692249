import React from 'react'
import Images from '../../helpers/Images'
import './Stores.css'

export const Stores = () => {
    return (
        <div className="wrapper_stores">
            <img
                className="logos"
                alt=""
                src={Images.outo.appStore} 
                onClick={() => window.open("https://apps.apple.com/mx/app/outo/id1633109274", "_blank")}
                />
            <img
                className="logos"
                alt=""
                src={Images.outo.playStore} 
                onClick={() => window.open("https://play.google.com/store/apps/details?id=com.outoapp&hl=es_MX&gl=US", "_blank")}                
                />
                
        </div>
    )
}
