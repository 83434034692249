import { Button, Modal } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Images from '../helpers/Images'

import { PriceInfo, IconList } from './'

import './AlertService.css'


const AlertService = () => {
  const notifications = [
    'Hoy No Circula',
    'Sanciones Ambientales',
    'Verificación',
    'FotoCívicas',
    'Tenencia / Refrendo',
    'Tarjeta de Circulación',
    'Infracciones',
    'Renovación de Placas'
  ]

  const vehicular = [
    {
      label: 'Multa extemporánea por Verificación',
      price: '$1,900'
    },
    {
      label: 'Tenencia: actualización + recargos*',
      price: '$250'
    },
    {
      label: 'Corralón: multa, arrastre, piso**',
      price: '$1,200'
    }
  ];

  const assistant = [
    {
      label: 'Grúa (Local)',
      price: '$1,250'
    },
    {
      label: 'Auxilio Vial: llanta, batería, gasolina',
      price: '$250'
    },
    {
      label: 'Abogado (Telefónico)',
      price: '$500'
    },
    {
      label: 'Ambulancia',
      price: '$3,500'
    },
    {
      label: 'Abogado (Presencial)',
      price: '$2,500'
    }
  ]
  const [open, setOpen] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const goToPage = (page) => {
    navigate(page)
  }




  return (
    <div className='alert_service'>
      <div className='row'>
        <div className='left'>
          <div className='alert_title'>
            <p className='service_title'>Alertas vehiculares</p>
            <img alt='crow' src={Images.outo.crown} />
          </div>
          <div className='screens_app_wrap'>
            <img className='screens_app mobile' alt='screens_app' src={Images.outo.screens_app} />
          </div>
          <p className='section_description'> El servicio de Alertas está disponible para vehículos con placas registradas en CDMX, EdoMex y Morelos. Según el Estado, las notificaciones pueden variar: </p>
          <div className='list'>
            <IconList
              list={notifications}
              icon={Images.icons.circle_check}
            />
          </div>
          <p className='note'>* Incluye un reporte mensual vía mail</p>
          <div className='buttons'>
            <Button
              className='show_button border_button'
              onClick={() => goToPage('price')}
            >
              Más información
            </Button>
            <Button
              className='show_button light_button'
              onClick={handleOpen}
            >
              Ver Análisis de Precios
            </Button>
          </div>
          <Modal
            open={!!open}
            onClose={handleClose}
          >
            <div>

              <div className='modal_box'>
                <div className='close_modal mobile'>
                  <img alt='remove' src={Images.icons.remove} onClick={handleClose} />
                </div>
                <div className='modal_container'>
                  <span className='modal-title'>Análisis de Precios del mercado</span>
                  <PriceInfo
                    title="Control Vehicular"
                    subtitle="Monto a pagar"
                    list={vehicular}
                    total="$3,350"
                    letters={[
                      "*Precio aprox. porque varía según los periodos vencidos ",
                      "** El derecho de piso cuesta $100 pesos al día"
                    ]}
                  >
                    <>
                      <span><b> FotoCívicas</b> (si tienes menos de 5 puntos)</span>
                      <p className='price'>Cursos + Trabajo comunitario</p>
                    </>
                  </PriceInfo>
                  <PriceInfo
                    title="Asistencia Vial"
                    subtitle="Precio mercado"
                    list={assistant}
                    total="$8,000"
                    letters={[
                      "*Precio aprox. que se cotizan en México"
                    ]}
                  />
                </div>
              </div>
            </div>

          </Modal>
        </div>
        <div className='right'>
          <img className='screens_app desktop' alt='screens_app' src={Images.outo.screens_app} />
        </div>
      </div>
    </div >
  )
}

export default AlertService