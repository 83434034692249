const storage_version = 3;
export const loadState = () => {
  try {
    const serializedData = localStorage.getItem('state')
    if (serializedData === null){
      return undefined 
    }
    const state = JSON.parse(serializedData)
    return state.storage_version ? (state.storage_version === storage_version ? state : undefined) : undefined;
  } catch (error) {
    return undefined
  }
}
export const saveState = (state) => {
  try {
    state = {...state,storage_version};
    let serializedData = JSON.stringify(state)
    localStorage.setItem('state', serializedData)
  } catch (error) {
	
  }
}